<template>
    <div>
        <div v-if="device" class="container repair-container">

            <h3 class="mt-4">Quality Control</h3>

            <div class="card-grid vertical">
                <div v-for="(check, idx) in checks" :key="`check-${idx}`" class="card-link-container">
                    <div class="card">
                        <div class="card-body flex-row align-items-center">
                            <template v-if="check.loading">
                                <animated-loading class="qc-icon"/>
                                <div class="flex-fill">
                                    Querying <strong>{{ check.title }}</strong> status...
                                </div>
                            </template>

                            <template v-else>
                                <success-animated class="qc-icon" v-if="check.pass === true"/>
                                <error-animated class="qc-icon" v-else-if="check.pass === false"/>
                                <warning-animated class="qc-icon" v-else/>

                                <div class="flex-fill">
                                    {{ check.message }}
                                </div>
                            </template>

                            <div>
                                <button class="btn btn-success" v-if="check.pass !== true && check.loading === false"
                                        @click="checkQualityControl(check.title)">
                                    Retry {{ check.title }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <fixed-footer
                :class="{'active': canContinue === true}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import SuccessAnimated from "../../components/animatedIcons/SuccessAnimated";
import WarningAnimated from "../../components/animatedIcons/WarningAnimated";
import ErrorAnimated from "../../components/animatedIcons/ErrorAnimated";

export default {
    components: {ErrorAnimated, WarningAnimated, SuccessAnimated},
    props: ["jobId", "deviceId"],

    data() {
        return {
            checks: []
        }
    },

    watch: {
        '$route': 'setUp'
    },

    mounted() {
        this.setUp();
    },

    computed: {
        ...mapGetters([
            "device",
            "nextRepairStep",
            "prevRepairStep"
        ]),

        canContinue() {
            if (!this.checks.length) return false;

            if (this.$route.meta.title === 'IQC') {
                return this.checks.filter(c => c.pass !== null).length > 0;
            }

            return this.checks.every(c => c.pass === true);
        }
    },

    methods: {
        ...mapActions([
            "loadDeviceForJob",
            "setCurrentStep",
        ]),

        setUp() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                }).then(() => {

                    if (this.$route.meta.title === 'IQC') {
                        this.checks = [{
                            title: 'IQC',
                            pass: null,
                            loading: true,
                            message: '',
                        }];
                    }

                    if (this.$route.meta.title === 'OQC') {
                        this.checks = [{
                            title: 'OQC',
                            pass: null,
                            loading: true,
                            message: '',
                        },
                            {
                                title: 'GM',
                                pass: null,
                                loading: true,
                                message: '',
                            }];
                    }

                    this.checkQualityControl();
                });
            });
        },

        checkQualityControl(only = null) {
            for (let check of this.checks) {
                if (only !== null) {
                    if (check.title !== only) {
                        continue;
                    }
                }

                console.log(`Querying QC for: ${check.title}`);
                check.loading = true;

                this.$http.get(`/api/v4/orders/devices/${this.deviceId}/check-${check.title.toLowerCase()}-status`)
                    .then((response) => {
                        check.pass = true;
                        if (Array.isArray(response.data.data) && response.data.data.includes('Skip')) {
                            check.message = `${check.title} not required`;
                        } else {
                            check.message = `${check.title} has PASSED`;
                        }
                        check.loading = false;
                    })
                    .catch((e) => {
                        if ([403, 404].includes(e.response.status) || e.response.status.toString().startsWith('5')) {
                            // The backend returns a 502 for this request if GSPN is unreachable.
                            // or a 404 if the device has yet to be seen by GSPN
                            // or a 403 if we forget to set permissions... (doh!)
                            check.pass = null;
                        } else {
                            // Everything else is a warning...
                            check.pass = false;
                        }

                        if (e.response.data.messages && Array.isArray(e.response.data.messages)) {
                            check.message = e.response.data.messages[0];
                        } else if (e.response.data.message){
                            check.message = e.response.data.message;
                        } else if (e.response.message) {
                            check.message = e.response.message;
                        } else {
                            check.pass = null;
                            check.message = e.message || 'Unknown Error';
                        }

                        check.loading = false;
                    })
            }
        }
    }
}
</script>

<style scoped>
.qc-icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
</style>